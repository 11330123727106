<template>
    <el-dialog title="경기등록/수정" :visible.sync="outerVisible" @close="close">
        <el-dialog
                width="60%"
                title="리그선택"
                :visible.sync="innerVisible"
                append-to-body>
            <div style="width: 100%;display: flex;justify-content:flex-start; align-items: center;flex-wrap: wrap;">

                <el-radio border size="mini" :label="item.id" style="margin: 2px 2px"
                          v-model="game.leagueId"
                          v-for="(item,index) in leagueList" @change="leagueChange(item)">{{item.leagueName}}
                </el-radio>

            </div>

        </el-dialog>
        <h4>종목</h4>
        <div class="gametypes">
            <el-button type="primary" size="mini" v-for="item in gameTypeList" @click="getLeagueByGameTypeId(item.id)">
                {{item.typeName}}
            </el-button>
        </div>
        <h5>경기정보</h5>
        <div class="gameinfo">
            <div class="g1">
                <div style="width: 100%;">
                    <el-radio size="mini" v-model="game.kind" :label="managerConst.Game.GAME_KIND_1X2" border>승무패
                    </el-radio>
                    <el-radio size="mini" v-model="game.kind" :label="managerConst.Game.GAME_KIND_HANDICAP" border>핸디캡
                    </el-radio>
                    <el-radio size="mini" v-model="game.kind" :label="managerConst.Game.GAME_KIND_OVERUNDER" border>
                        오버언더
                    </el-radio>
                </div>
            </div>
            <div class="g1">
                <div style="width: 100%;">
                    <el-radio size="mini" v-model="game.displayPosition"
                              :label="managerConst.Game.GAME_DISPLAYPOSITION_1x2" border>크로스 페이지
                    </el-radio>
                    <el-radio size="mini" v-model="game.displayPosition"
                              :label="managerConst.Game.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER" border>핸디/오언 페이지
                    </el-radio>
                    <el-radio size="mini" v-model="game.displayPosition"
                              :label="managerConst.Game.GAME_DISPLAYPOSITION_SPECIAL" border>스페셜 페이지
                    </el-radio>
                    <el-radio size="mini" v-model="game.displayPosition"
                              :label="managerConst.Game.GAME_DISPLAYPOSITION_LIVE" border>라이브 페이지
                    </el-radio>
                </div>
            </div>
            <div class="g1">
                <div style="width: 100%;">
                    <el-radio size="mini" v-model="game.upoddsable" :label="managerConst.NOT" border>자동업데이트(아니오,NOT)
                    </el-radio>
                    <el-radio size="mini" v-model="game.upoddsable" :label="managerConst.YES" border>자동업데이트(예,YES)
                    </el-radio>
                </div>
            </div>
            <div class="g1">
                <div style="width: 100%;">
                    <el-radio size="mini" v-model="game.attribute" :label="managerConst.Game.GAME_ATTR_FULLTIME" border>
                        풀타임
                    </el-radio>
                    <el-radio size="mini" v-model="game.attribute" :label="managerConst.Game.GAME_ATTR_SECTIONTIME"
                              border>하프타임
                    </el-radio>
                </div>
            </div>
            <div class="g1">
                <div style="width: 100%;">
                    <el-radio size="mini" v-model="game.islive" :label="managerConst.NOT" border>라이브(아니오,NOT)</el-radio>
                    <el-radio size="mini" v-model="game.islive" :label="managerConst.YES" border>라이브(예,YES)</el-radio>
                </div>
            </div>
          <div class="g1">
            <div style="width: 100%;">
              <el-radio size="mini" v-model="game.display" :label="managerConst.YES" border>보여짐</el-radio>
              <el-radio size="mini" v-model="game.display" :label="managerConst.NOT" border>숨겨짐</el-radio>
            </div>
          </div>
            <div v-if="this.mode === managerConst.MODE_SAVE" class="g1">
                <div style="width: 100%;">
                    <el-checkbox v-model="game.handicapRelation">핸디캡연동</el-checkbox>
                    <el-checkbox v-model="game.overunderRelation">오버언더연동</el-checkbox>
                    <el-checkbox v-model="game.specialRelation">스페셜연동</el-checkbox>
                </div>
            </div>

            <div style="width: 33.3%">
                <h5 class="text-red">UC코드</h5>
                <el-input size="mini" v-model="game.unifiedCode"></el-input>
            </div>
            <div style="width: 33.3%">
                <h5 class="text-red">그룹코드</h5>
                <el-input size="mini" v-model="game.groupCode"></el-input>
            </div>
            <div style="width: 33.3%">
                <h5>리그</h5>
                <el-input size="mini" v-model="game.league.leagueName"></el-input>
            </div>
            <div style="width: 49.5%">
                <h5>시작일시(날짜,시,분)</h5>
                <div style="display: flex;justify-content: space-between;flex-wrap: nowrap;">
                    <el-date-picker
                            size="mini"
                            v-model="startDate"
                            type="date"
                            placeholder="날짜선택" style="width: 33%">
                    </el-date-picker>
                    일
                    <el-input size="mini" v-model="startHour" style="width: 100px;margin-left: 5px"></el-input> 시
                    <el-input size="mini" v-model="startMinite" style="width: 100px;margin-left: 5px"></el-input> 분
                </div>
            </div>
            <div style="width:100%;display: flex;justify-content: space-between;align-items: center;margin-top: 5px">
                <div style="width: 34%">
                    <h5 style="text-align: center">W1 명칭</h5>
                    <el-input size="mini" v-model="game.homeTeamName"></el-input>
                </div>
                <div style="width: 10%">
                    <h5 style="text-align: center">W1 배당</h5>
                    <el-input size="mini" v-model="game.homeTeamOdds"></el-input>
                </div>
                <div style="width: 10%" v-if="game.kind === managerConst.Game.GAME_KIND_1X2">
                    <h5 style="text-align: center">무배당</h5>
                    <el-input size="mini" v-model="game.drawOdds"></el-input>
                </div>
                <div style="width: 10%" v-if="game.kind === managerConst.Game.GAME_KIND_HANDICAP">
                    <h5 style="text-align: center">핸디캡</h5>
                    <el-input size="mini" v-model="game.handicapVal"></el-input>
                </div>
                <div style="width: 10%" v-if="game.kind === managerConst.Game.GAME_KIND_OVERUNDER">
                    <h5 style="text-align: center">오버언더</h5>
                    <el-input size="mini" v-model="game.overunderVal"></el-input>
                </div>
                <div style="width: 10%">
                    <h5 style="text-align: center">W2 배당</h5>
                    <el-input size="mini" v-model="game.awayTeamOdds"></el-input>
                </div>

                <div style="width: 34%">
                    <h5 style="text-align: center">W2 명칭</h5>
                    <el-input size="mini" v-model="game.awayTeamName"></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="save()">저장하기</el-button>
            <el-button @click="outerVisible = false">취소</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {
        getById,
        getLeagueList,
        saveGame,
        updateGame,
    } from "../../../network/manager/sportsRequest";
    import managerConst from "../../../common/administrator/managerConst";
    import {getGameTypeList} from "../../../network/manager/gameTypeRequest";

    export default {
        name: "SportsEditToast",
        data() {
            return {
                gameId: null, //
                closed: false,
                outerVisible: false,
                innerVisible: false,
                game: {
                    'leagueId': null,
                    'league': {leagueName: ''},
                    'kind': managerConst.Game.GAME_KIND_1X2,
                    'displayPosition': managerConst.Game.GAME_DISPLAYPOSITION_1x2,
                    'upoddsable': managerConst.NOT,
                    'attribute': managerConst.Game.GAME_ATTR_FULLTIME,
                    'islive': managerConst.NOT,
                },
                managerConst: managerConst,
                mode: managerConst.MODE_SAVE, //save:등록 ,edit:수정,
                leagueList: [],
                gameTypeList: [],
                startDate: this.$moment().format("yyyy-MM-DD"),
                startHour: '',
                startMinite: '',
                hours: [
                    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
                    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                    '20', '21', '22', '23'
                ],
                minites: [
                    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
                    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
                    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
                    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
                    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
                    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
                ],

            }
        },
        methods: {
            close() {
                this.game = {
                    'leagueId': null,
                    'league': {leagueName: ''},
                    'kind': managerConst.Game.GAME_KIND_1X2,
                    'displayPosition': managerConst.Game.GAME_DISPLAYPOSITION_1x2,
                    'upoddsable': managerConst.NOT,
                    'attribute': managerConst.Game.GAME_ATTR_FULLTIME,
                    'islive': managerConst.NOT,
                }
                this.closed = true
                this.outerVisible = false
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            getLeagueByGameTypeId(gameTypeId) {
                getLeagueList({'gameTypeId': gameTypeId}, 1, 10000, null).then(res => {
                    this.leagueList = (res.data.data)
                    this.innerVisible = true
                })
            },
            leagueChange(league) {
                this.innerVisible = false
                this.game.leagueId = league.id
                this.game.league.leagueName = league.leagueName
            },
            save() {

                this.game.startTime = this.$moment(this.startDate).format('yyyy-MM-DD') + ' ' + this.startHour + ':' + this.startMinite;
                if (this.mode === managerConst.MODE_SAVE) {
                    saveGame(this.game).then(res => {
                        if (res.data.success) {
                            this.$message({
                                showClose: false,
                                duration: 1500,
                                message: '경기등록이 완료되였습니다,대기중에서 확인해주세요',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                showClose: false,
                                duration: 1500,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }else {
                    updateGame(this.game).then(res => {
                        if (res.data.success) {
                            this.$message({
                                showClose: false,
                                duration: 1500,
                                message: '경기 업데이트 완료되였습니다',
                                type: 'success'
                            });
                            this.$bus.$emit('update_sports_end');
                            this.close()
                        } else {
                            this.$message({
                                showClose: false,
                                duration: 1500,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }
            }
        },
        created() {
            this.outerVisible = true
            if (this.mode === 'edit') {
                getById(this.gameId).then(res => {
                    if (res.data.success) {
                        this.game = res.data.data
                        if (this.game) {
                            let st = this.game.startTime.split(" ");
                            this.startDate = st[0];
                            this.startHour = st[1].split(":")[0];
                            this.startMinite = st[1].split(":")[1];
                        }
                    }
                })
            }
            getGameTypeList().then(res => {
                this.gameTypeList = res.data.data
            })
        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.game = {
                        'leagueId': null,
                        'league': {leagueName: ''},
                        'kind': managerConst.Game.GAME_KIND_1X2,
                        'displayPosition': managerConst.Game.GAME_DISPLAYPOSITION_1x2,
                        'upoddsable': managerConst.NOT,
                        'attribute': managerConst.Game.GAME_ATTR_FULLTIME,
                        'islive': managerConst.NOT,
                    }
                    this.outerVisible = false
                    this.innerVisible = false
                    this.destroyElement()
                }
            }
        },

    }
</script>

<style scoped>
    .gameinfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .g1 {
        margin-top: 5px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #b3d8ff;
        padding: 5px;
        border-radius: 3px;
    }
</style>